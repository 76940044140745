import { PlanType } from '@app/+authenticated/+reports/shared/subscriptions/subscription.model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { PermissionCheck } from '../auth/permission.helper';

export enum DashboardFilterType {
  MY_SCHEDULE = 'My schedule',
  MY_TIMESHEETS = 'My timesheets',
  MY_OPEN_SHIFTS = 'My open shifts',
  MY_EXCHANGES = 'My exchanges',
  MY_NEWS_ITEMS = 'My news items',
  BIRTHDAYS = 'Birthdays',
  WEATHER_FORECASTS = 'Weather forecasts',
  ENDING_CONTRACTS = 'Ending contracts',
  ABSENCE_PERCENTAGE = 'Absence percentage',
  SALARY_COST_PERCENTAGE = 'Salary cost percentage',
  SALARY_PER_HOUR = 'Salary per hour',
  TURNOVER_PER_HOUR = 'Turnover per hour',
  WORKED_VS_SCHEDULED_HOURS = 'Worked vs. scheduled hours',
  SALARY_COST_PERCENTAGE_COC = 'Salary cost percentage including CoC',
}

// Only for translations
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const translations = [
  _('My schedule'),
  _('My timesheets'),
  _('My open shifts'),
  _('My exchanges'),
  _('My news items'),
  _('Birthdays'),
  _('Weather forecasts'),
  _('Ending contracts'),
  _('Absence percentage'),
  _('Salary cost percentage'),
  _('Salary per hour'),
  _('Turnover per hour'),
  _('Worked vs. scheduled hours'),
  _('Salary cost percentage including CoC'),
];

export type PermissionCheckType = 'all' | 'reports' | 'any';

interface DashBoardFilterViewConfig {
  subscriptionPlan: PlanType;
  hasPermission?: PermissionCheck;
  permissionCheckType?: PermissionCheckType;
}

export const pagedashboardFilterViewConfig: {
  [key: string]: DashBoardFilterViewConfig;
} = {
  [DashboardFilterType.SALARY_COST_PERCENTAGE_COC]: {
    subscriptionPlan: PlanType.EARLY_ADOPTER,
    permissionCheckType: 'reports',
    hasPermission: {
      permissions: ['View reports', 'View salary'],
      userId: 'me',
      departments: 'any',
    },
  },
  [DashboardFilterType.MY_SCHEDULE]: {
    subscriptionPlan: PlanType.FREE,
  },
  [DashboardFilterType.MY_TIMESHEETS]: {
    subscriptionPlan: PlanType.FREE,
    permissionCheckType: 'any',
    hasPermission: {
      permissions: ['View all timesheets', 'View own timesheet'],
      departments: 'any',
      userId: 'me',
    },
  },
  [DashboardFilterType.MY_OPEN_SHIFTS]: {
    subscriptionPlan: PlanType.BASIC,
  },
  [DashboardFilterType.MY_EXCHANGES]: {
    subscriptionPlan: PlanType.BASIC,
  },
  [DashboardFilterType.MY_NEWS_ITEMS]: {
    subscriptionPlan: PlanType.FREE,
  },
  [DashboardFilterType.BIRTHDAYS]: {
    subscriptionPlan: PlanType.BASIC,
    hasPermission: {
      permissions: 'View user details',
      departments: 'any',
      userId: 'me',
    },
  },
  [DashboardFilterType.WEATHER_FORECASTS]: {
    subscriptionPlan: PlanType.BASIC,
  },
  [DashboardFilterType.ENDING_CONTRACTS]: {
    subscriptionPlan: PlanType.BASIC,
    hasPermission: {
      permissions: 'View contracts',
      departments: 'any',
      userId: 'me',
    },
  },
  [DashboardFilterType.ABSENCE_PERCENTAGE]: {
    subscriptionPlan: PlanType.BASIC,
    permissionCheckType: 'reports',
    hasPermission: {
      permissions: ['View reports', 'View absentee'],
      userId: 'me',
      departments: 'any',
    },
  },
  [DashboardFilterType.SALARY_COST_PERCENTAGE]: {
    subscriptionPlan: PlanType.EARLY_ADOPTER,
    permissionCheckType: 'reports',
    hasPermission: {
      permissions: ['View reports', 'View salary'],
      userId: 'me',
      departments: 'any',
    },
  },
  [DashboardFilterType.SALARY_PER_HOUR]: {
    subscriptionPlan: PlanType.EARLY_ADOPTER,
    permissionCheckType: 'reports',
    hasPermission: {
      permissions: ['View reports', 'View salary'],
      userId: 'me',
      departments: 'any',
    },
  },
  [DashboardFilterType.TURNOVER_PER_HOUR]: {
    subscriptionPlan: PlanType.EARLY_ADOPTER,
    permissionCheckType: 'reports',
    hasPermission: {
      permissions: ['View reports', 'View forecast'],
      userId: 'me',
      departments: 'any',
    },
  },
  [DashboardFilterType.WORKED_VS_SCHEDULED_HOURS]: {
    subscriptionPlan: PlanType.BASIC,
    permissionCheckType: 'reports',
    hasPermission: {
      permissions: 'View reports',
      userId: 'me',
    },
  },
};
