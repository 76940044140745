import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../environments/environment';
import { WindowRef } from '../shared/fixed/window';

declare global {
  interface Window {
    _hsq: unknown[];
  }
}

@Injectable({
  providedIn: 'root',
})
export class HubSpotService {
  public crmConsent: boolean;
  public isChatEnabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private constructor(
    private _window: WindowRef,
    private cookieService: CookieService,
  ) {}

  private getNativeWindow() {
    return this._window.nativeWindow;
  }

  public setIdentification(email: string, token: string) {
    this.getNativeWindow().hsConversationsSettings = {
      identificationEmail: email,
      identificationToken: token,
    };
  }

  private loadChatWidget(checkChatActiveCookie: boolean) {
    const isChatActive = this.cookieService.get('hubspotChatVisible') === 'true';
    if (checkChatActiveCookie && !isChatActive) {
      return;
    }

    const active = this.isChatActive();

    if (!active) {
      this.getNativeWindow().HubSpotConversations.widget.load();
      return;
    }

    this.getNativeWindow().HubSpotConversations.widget.refresh({ openToNewThread: true });
  }

  public closeChatWidget() {
    this.getNativeWindow().HubSpotConversations?.widget?.close();
  }

  public removeChatWidget() {
    this.getNativeWindow().HubSpotConversations?.widget?.remove();
  }

  public isChatActive(): boolean {
    const status = this.getNativeWindow().HubSpotConversations.widget.status();

    return status.loaded;
  }

  public initializeChatWidget(checkChatActiveCookie = false): void {
    if (this.getNativeWindow().HubSpotConversations) {
      this.loadChatWidget(checkChatActiveCookie);
      return;
    }

    const onConversationsAPIReady = () => {
      this.loadChatWidget(checkChatActiveCookie);
    };

    window['hsConversationsOnReady'] = [onConversationsAPIReady];
  }

  public identify(email: string) {
    const _hsq = (window._hsq = window._hsq || []);

    _hsq.push(['identify', { email }]);

    _hsq.push([
      'trackCustomBehavioralEvent',
      {
        name: environment.hubSpotCustomBehavioralEvent,
        properties: {},
      },
    ]);
  }

  public loadScript(): void {
    // We set this default value here, so we can guarentee that it'll be present and have the `push`
    // method available, as the script we are inserting here might be blocked by the browser.
    this.getNativeWindow()._hsq = [];

    const hubSpotScript = document.createElement('script');
    hubSpotScript.src = environment.hubSpotScript;
    hubSpotScript.type = 'text/javascript';
    hubSpotScript.id = 'hs-script-loader';
    hubSpotScript.async = true;
    hubSpotScript.defer = true;

    const allScripts = document.getElementsByTagName('script');
    const lastScript = allScripts[allScripts.length - 1];

    lastScript.parentNode.insertBefore(hubSpotScript, lastScript);
  }
}
