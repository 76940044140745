export const isAnyFilterActive = (filters): boolean => {
  // List of filters to ignore
  const ignoreList = ['compact'];
  // List of filters to check if they are not empty
  const nonBooleanFilters = ['employeeSearch'];

  return Object.keys(filters).some((key) => {
    const filter = filters[key];

    if (ignoreList.includes(key)) {
      return false;
    }

    if (Array.isArray(filter)) {
      return filter.length > 0;
    }

    switch (typeof filter) {
      case 'boolean':
        return filter;
      case 'object':
        return isAnyFilterActive(filter);
      default:
        return nonBooleanFilters.includes(key) && !!filter;
    }
  });
};
