import defaultsDeep from 'lodash-es/defaultsDeep';
import merge from 'lodash-es/merge';
import u from 'updeep';

import { UnsafeAction as Action } from '../interfaces';
import { DashboardFilterType } from './page-filters-dashboard.config';
import { pageFilterActionType } from './page-filters.action';
import { PageFiltersState, PageState } from './page-filters.model';

const INITIAL_STATE = '@ngrx/store/init';

export const initialState: PageFiltersState = {
  timesheet: {
    show: {
      absence: true,
      schedule: true,
      employee: true,
    },
    columns: {
      break: true,
      location: false,
      department: false,
      team: true,
      shift: true,
      surcharge: false,
      km: false,
      meals: false,
      note: false,
    },
    name: '',
    employeeId: '',
    status: {
      approved: true,
      pending: true,
      declined: false,
    },
    type: {
      manual: true,
      clocked: true,
      activeClock: true,
      onlyConflict: false,
    },
    customFields: {},
  },

  schedule: {
    showTypes: {
      scheduledShifts: true,
      openShifts: true,
      requiredShifts: true,
      absence: true,
      availability: true,
      event: true,
      teamNotes: false,
      weatherForecast: false,
    },
    showOptions: {
      publishStatus: false,
      totals: false,
      budget: false,
      forecast: false,
      violationsOnly: false,
      includeEmployeesWithoutData: true,
      loanedShifts: true,
      conflicts: false,
      emptyTeams: true,
      compact: false,
    },
    department: {},
    skills: [],
    employeeSearch: '',
  },

  employee: {
    selectedEmployee: '',
    selectedPage: 'overview',
    employeeSchedule: {
      date: '',
    },
    search: '',
    status: {
      active: true,
      inactive: true,
      invited: true,
      notInvited: true,
    },
    skills: [],
  },
  diary: {},
  news: {},
  report: {
    sidebarIsOpen: true,
  },
  mySchedule: {
    date: '',
  },
  dashboard: {
    [DashboardFilterType.MY_SCHEDULE]: {
      visibility: true,
      widgetPeriod: 1,
    },
    [DashboardFilterType.MY_TIMESHEETS]: {
      visibility: true,
      widgetPeriod: 1,
    },
    [DashboardFilterType.MY_OPEN_SHIFTS]: {
      visibility: false,
      widgetPeriod: 1,
    },
    [DashboardFilterType.MY_EXCHANGES]: {
      visibility: false,
      widgetPeriod: 1,
    },
    [DashboardFilterType.MY_NEWS_ITEMS]: {
      visibility: false,
      widgetPeriod: 1,
    },
    [DashboardFilterType.BIRTHDAYS]: {
      visibility: false,
      widgetPeriod: 1,
    },
    [DashboardFilterType.ENDING_CONTRACTS]: {
      visibility: false,
      widgetPeriod: 1,
    },
    [DashboardFilterType.ABSENCE_PERCENTAGE]: {
      visibility: false,
    },
    [DashboardFilterType.SALARY_COST_PERCENTAGE]: {
      visibility: false,
    },
    [DashboardFilterType.SALARY_PER_HOUR]: {
      visibility: false,
    },
    [DashboardFilterType.TURNOVER_PER_HOUR]: {
      visibility: false,
    },
    [DashboardFilterType.WORKED_VS_SCHEDULED_HOURS]: {
      visibility: false,
    },
    [DashboardFilterType.SALARY_COST_PERCENTAGE_COC]: {
      visibility: false,
    },
  },
};

export function PageFiltersReducer(state: PageFiltersState = initialState, action: Action): PageFiltersState {
  const { type, payload } = action;

  switch (type) {
    //make sure that missing values from the localStorage sync are added;
    case INITIAL_STATE:
      const stateWithDefaults = defaultsDeep(state, initialState) as PageFiltersState;
      //assign to new object because defaultsDeep mutates state;
      return { ...stateWithDefaults };

    case pageFilterActionType.LOAD_FILTERS:
      const filter: PageFiltersState = payload;
      if (!filter.dashboard) {
        filter.dashboard = initialState.dashboard;
      }
      return merge({}, state, filter);

    case pageFilterActionType.SET_FILTER:
    case pageFilterActionType.SET_FILTERS:
      const { page } = payload;
      const pageSlice = state[page] || {};

      return {
        ...state,
        [page]: PageReducer(pageSlice, action),
      };

    default:
      return state;
  }
}

function PageReducer(state: PageState, action: Action): PageState {
  const { type, payload } = action;

  switch (type) {
    case pageFilterActionType.SET_FILTER:
      return u.updateIn(payload.filter, payload['filterValue'], state);

    case pageFilterActionType.SET_FILTERS:
      const filters = payload.filters;

      return {
        ...state,
        ...filters,
      };

    default:
      return state;
  }
}
