import { Injectable } from '@angular/core';
import { isAnyFilterActive } from '@app/+authenticated/shared/filters-toggle/filters-toggle.helper';
import { Store } from '@ngrx/store';
import { getPermissionState, hasPermission } from '@reducers/auth/permission.helper';
import isEmpty from 'lodash-es/isEmpty';
import isString from 'lodash-es/isString';
import zipObject from 'lodash-es/zipObject';
import { createSelector } from 'reselect';
import { throwError as observableThrowError, of } from 'rxjs';
import { catchError, filter, tap, withLatestFrom } from 'rxjs/operators';

import { ApiGateway } from '../../api/ApiGateway.service';
import { getArrayDifference, getArrayIntersection } from '../../shared/array.helper';
import { format } from '../../shared/date.helper';
import { HubSpotService } from '../../startup/hub-spot.service';
import { getAccount } from '../account/account.service';
import { getAuthenticatedDepartmentIds } from '../auth/auth.service';
import { AppState } from '../index';
import { getContractTypeIds } from '../orm/contract-type/contract-type.service';
import { canViewContracts } from '../orm/contract/contract.service';
import { SelectedDepartmentsAction } from '../selected-departments/selected-departments.action';
import { canViewSkills, getSkillIds } from './../orm/skill/skill.service';
import { PageFiltersAction } from './page-filters.action';
import {
  DiaryFilterState,
  EmployeeContractTypeFilter,
  EmployeeFilterState,
  EmployeeScheduleFilterState,
  MyScheduleFilterState,
  NewsFilterState,
  PageFiltersState,
  ReportFilterState,
  ScheduleFilterState,
  TimesheetFilterState,
} from './page-filters.model';
import { initialState as pageFilterInitialState } from './page-filters.reducer';

export const filterPersistenceDebounceTime = 500;

export interface PageFilterRequest {
  filters?: string;
  departments?: string;
  crm_consent?: boolean;
}

@Injectable()
export class PageFiltersService {
  public constructor(
    private store: Store<AppState>,
    private api: ApiGateway,
    private hubspotService: HubSpotService,
  ) {}

  public setFilter(page: string, filter: string, value) {
    this.store.dispatch(PageFiltersAction.setFilter(page, filter, value));
  }

  public setFilters(page, filters) {
    this.store.dispatch(PageFiltersAction.setFilters(page, filters));
  }

  public loadFilters() {
    return this.api.get('users/filters').pipe(
      catchError((error) => observableThrowError(error)),
      withLatestFrom(
        this.store
          .select(getAuthenticatedDepartmentIds)
          .pipe(filter((authenticatedDepartmentIds) => !!authenticatedDepartmentIds)),
      ),
      tap(([data, authenticatedDepartmentIds]) => {
        this.hubspotService.crmConsent = !!data?.crm_consent;
        if (isEmpty(data)) {
          this.store.dispatch(PageFiltersAction.loadFilters({ ...pageFilterInitialState }));
          return;
        }

        let departmentFilter: string[] = isString(data.departments)
          ? JSON.parse(data.departments)
          : data.departments ?? [];
        const departmentIdsToRemove = getArrayDifference(departmentFilter, authenticatedDepartmentIds);
        departmentFilter = departmentFilter.filter((departmentId) => !departmentIdsToRemove.includes(departmentId));
        if (data.filters) {
          const filters = isString(data.filters) ? JSON.parse(data.filters) : data.filters;
          this.store.dispatch(PageFiltersAction.loadFilters(filters));
        }

        if (data.departments) {
          this.store.dispatch(
            SelectedDepartmentsAction.loadDepartments(
              departmentFilter.length > 0 ? departmentFilter : authenticatedDepartmentIds,
            ),
          );
        }
      }),
    );
  }

  public saveFilters(filters: PageFilterRequest) {
    if (filters['filters']) {
      filters['filters'] = JSON.parse(filters['filters']);
    }

    if (filters['departments']) {
      filters['departments'] = JSON.parse(filters['departments']);
    }

    return this.api.post('users/filters', filters).pipe(catchError((err) => of(undefined)));
  }
}

export const getPageFiltersState = (appState: AppState): PageFiltersState => appState.pageFilters;

export const getTimesheetFilters = createSelector(
  getPageFiltersState,
  (pageFilters): TimesheetFilterState => pageFilters.timesheet,
);

export const isAnyTimesheetFilterActive = createSelector(getTimesheetFilters, (timesheetFilters) =>
  isAnyFilterActive(timesheetFilters),
);

const getContractFilter = createSelector(getContractTypeIds, (contractTypeIds: string[]) => {
  const allContracts: string[] = ['noContract', ...contractTypeIds];
  return zipObject(
    allContracts,
    allContracts.map(() => true),
  );
});

export const getScheduleFilters = createSelector(
  getPageFiltersState,
  getContractFilter,
  canViewSkills,
  canViewContracts,
  getSkillIds,
  getAccount,
  getPermissionState,
  (
    pageFilters,
    contract: EmployeeContractTypeFilter,
    canViewSkills,
    canViewContracts,
    skillIds: string[],
    account,
    permissionState,
  ): ScheduleFilterState => {
    contract = canViewContracts ? { ...contract, ...pageFilters.schedule.contract } : { ...contract };
    const scheduleSkillFilters = pageFilters?.schedule?.skills ? pageFilters.schedule.skills : [];
    const usableSkills = getArrayIntersection(skillIds, scheduleSkillFilters);
    const skills = canViewSkills ? usableSkills : [];
    const violationsOnly = account?.country === 'NL' && pageFilters.schedule.showOptions.violationsOnly;
    const hasConflictFilter = hasPermission(
      { permissions: ['Create roster', 'Edit roster'], userId: 'me', departments: 'any' },
      permissionState,
    );

    return {
      ...pageFilters.schedule,
      contract,
      skills,
      showOptions: {
        ...pageFilters.schedule.showOptions,
        violationsOnly,
        conflicts: hasConflictFilter ? pageFilters?.schedule?.showOptions?.conflicts : false,
      },
    };
  },
);

export const isAnyScheduleFilterActive = createSelector(getScheduleFilters, (scheduleFilters) =>
  isAnyFilterActive(scheduleFilters),
);

export const getEmployeeFilters = createSelector(
  getPageFiltersState,
  (pageFilters): EmployeeFilterState => pageFilters.employee,
);

export const getDiaryFilters = createSelector(
  getPageFiltersState,
  (pageFilters): DiaryFilterState => pageFilters.diary,
);

export const getNewsFilters = createSelector(getPageFiltersState, (pageFilters): NewsFilterState => pageFilters.news);

export const getReportFilters = createSelector(
  getPageFiltersState,
  (pageFilters): ReportFilterState => pageFilters.report || {},
);

export const getMyScheduleFilters = createSelector(
  getPageFiltersState,
  (pageFilters): MyScheduleFilterState => pageFilters.mySchedule || { date: format(new Date(), 'yyyy-MM-dd') },
);

export const getEmployeeScheduleFilters = createSelector(
  getEmployeeFilters,
  (employeeFilters): EmployeeScheduleFilterState =>
    employeeFilters.employeeSchedule || { date: format(new Date(), 'yyyy-MM-dd') },
);

export const getLoanedShiftsFilter = createSelector(
  getScheduleFilters,
  (scheduleFilters): boolean => scheduleFilters?.showOptions?.loanedShifts,
);
