const ACTION_PREFIX = '[Page filters]';
export const pageFilterActionType = {
  LOAD_FILTERS: `${ACTION_PREFIX} load`,
  SET_FILTER: `${ACTION_PREFIX} set`,
  SET_FILTERS: `${ACTION_PREFIX} set multiple`,
};

export class PageFiltersAction {
  static loadFilters(payload) {
    return {
      type: pageFilterActionType.LOAD_FILTERS,
      payload,
    };
  }
  static setFilter(page: string, filter: string, filterValue: any) {
    return {
      type: pageFilterActionType.SET_FILTER,
      payload: {
        page,
        filter,
        filterValue,
      },
    };
  }

  static setFilters(page: string, filters: { [filter: string]: any }) {
    return {
      type: pageFilterActionType.SET_FILTERS,
      payload: {
        page,
        filters,
      },
    };
  }
}
